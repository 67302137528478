import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../Components/Header";
import { BASE_URL, GET_DONATION_YEAR, GET_DONOR_DATA, GET_DONOR_YEAR, GET_OBJ, GET_TOTAL_PLEDGES_BY_DONORS, INSERT_OBJ } from "../../utils/endpoints";
import styles from "./css/index.module.css";
import { createCsvString, downloadJsonArrayAsCsv } from "../../utils/functions";

const Admin = () =>{
    const [result, setResult] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(true);
    const [newData, setNewData] = useState('');
    //const [donorYear, setDonorYear] = useState('2021');
    const [startDonationYear, setStartDonationYear] = useState('2021');
    const [endDonationYear, setEndDonationYear] = useState('2021');
    //const [donorYearError, setDonorYearError] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [entity, setEntity] = useState('');
    const [donationYearError, setDonationYearError] = useState(false);

    const [type, setType] = useState('School');

    useEffect(() => {


        let getResult = async () => {
            await axios.get(BASE_URL + GET_OBJ[type])
            .then((response) => {
                if(Array.isArray(response.data))
                    setResult(response.data);
            }).catch((e) =>{
            
            })
        }
        if(type !== 'Reports')
            getResult();
        else
            setResult([])
    },[type,isSubmitting])


    const setBeneficiariesType = (type) => {
        setType(type);
    }

    const submitAddition = async (e) => {
        e.preventDefault();

        setIsSubmitted(false);
        setIsSubmitting(true);

        let END_POINT = INSERT_OBJ[type];

        await axios.post(BASE_URL + END_POINT, JSON.stringify({name : newData}))
        .then((response) => {if(response.status === 200) {console.log(response); setIsSubmitting(false);setNewData("")}})
        .catch((e) => console.log(e))

    }
/* 
    const downloadDonorYear = async (e) => {

        e.preventDefault();

        const response = await axios.get(`${BASE_URL}${GET_DONOR_YEAR}?year=${donorYear}`)

        if(response.status === 200){

            const hasContent = response.data.length > 0

            setDonorYearError(!hasContent)

            if(hasContent){
                const csvHeader = `Id,Name,Phone Number,Email,Pledge Amount,Total Amount Donated,Pledge Type\n`;
                downloadJsonArrayAsCsv(`${csvHeader}${createCsvString(response.data)}`, `Donors by Year - ${donorYear} - ${new Date()}`)
            }

        }

    }

 */    const downloadDonationYear = async (e) => {

        e.preventDefault();

        const response = await axios.get(`${BASE_URL}${GET_DONOR_DATA}?startYear=${startDonationYear}&endYear=${endDonationYear}&searchText=${searchText}&entity=${entity}`)

        if(response.status === 200){

            const hasContent = response.data.length > 0

            setDonationYearError(!hasContent)

            if(hasContent){

                let keys = Object.keys(response.data[0]);
                const csvHeader = `${keys.toString()}\n`;
                downloadJsonArrayAsCsv(`${csvHeader}${createCsvString(response.data)}`, `Report by Year - ${startDonationYear} to ${endDonationYear} - ${new Date()}`)
            }

        }

    }
/* 
    const downloadTotalPledges = async (e) => {

        e.preventDefault();

        const response = await axios.get(`${BASE_URL}${GET_TOTAL_PLEDGES_BY_DONORS}`)

        if(response.status === 200){
                const csvHeader = `Id,Name,Phone Number,Email,Pledge Type,Year,Total Pledges\n`;
                downloadJsonArrayAsCsv(`${csvHeader}${createCsvString(response.data)}`, `Total Pledges By Donors - ${new Date()}`)
        }
    }

 */    return<>
        <Header/>
        <div className={styles['container']}>
            <h2>Admin</h2>
            <div className={styles['types']}>
                <button className={type === 'School' ? styles['active'] : null} onClick={() => setBeneficiariesType('School')}>School</button>
                <button className={type === 'Programme' ? styles['active'] : null} onClick={() => setBeneficiariesType('Programme')}>Programme</button>
                <button className={type === 'Cycle' ? styles['active'] : null} onClick={() => setBeneficiariesType('Cycle')}>Cycle</button>
                <button className={type === 'Partner' ? styles['active'] : null} onClick={() => setBeneficiariesType('Partner')}>Partner</button>
                <button className={type === 'Reports' ? styles['active'] : null} onClick={() => setBeneficiariesType('Reports')}>Reports</button>
            </div>
             
            { type === 'Reports' ?
            <div>
                <h3>Reports</h3>

                {/* <div className={styles['report-group']}>
                    <p>Get All Donors by Year:</p>
                    <form onSubmit={(e) => downloadDonorYear(e)} className={styles['form']}>
                        <input name="newData" type="text" value={donorYear} onChange={(e) => setDonorYear(e.target.value)}/>
                        <input type={'submit'} value={'Download'} />
                    </form> 
                    {donorYearError && <p>Data does not exist for this year</p>}                   
                </div>
                <div className={styles['report-group']}>
                    <p>Get All Donations by Year:</p>
                    <form onSubmit={(e) => downloadDonationYear(e)} className={styles['form']}>
                        <input name="newData" type="text" value={donationYear} onChange={(e) => setDonationYear(e.target.value)}/>
                        <input type={'submit'} value={'Download'} />
                    </form>    
                    {donationYearError && <p>Data does not exist for this year</p>}                                   
                </div>
                <div className={styles['report-group']}>
                    <p>Get Total Pledges By Donors </p>
                    <form onSubmit={(e) => downloadTotalPledges(e)} className={styles['form']}>
                        <input type={'submit'} value={'Download'} />
                    </form>                    
                </div> */}
                <div className={styles['report-group']}>
                    <p>Get All Donations by Entity & Year:</p>
                    <form onSubmit={(e) => downloadDonationYear(e)} className={`${styles['form']} ${styles['report']}`}>
                        <select value={entity} onChange={e => setEntity(e.target.value)}>
                            <option value={""}>Select Entity</option>
                            <option value={"beneficiary"}>beneficiary</option>
                            <option value={"school"}>school</option>
                            <option value={"partner"}>partner</option>
                            <option value={"donor"}>donor</option>
                        </select>
                        <div>
                            <label>Search</label>
                            <input name="newData" type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)}/>
                        </div>
                        <div>
                            <label>From</label>
                            <input name="newData" type="text" value={startDonationYear} onChange={(e) => setStartDonationYear(e.target.value)}/>
                        </div>
                        <div>
                            <label>To</label>
                            <input name="newData" type="text" value={endDonationYear} onChange={(e) => setEndDonationYear(e.target.value)}/>
                        </div>
                        <input type={'submit'} value={'Download'} />
                    </form>    
                    {donationYearError && <p>Data does not exist for this criteria</p>}                                   
                </div>
            </div> :
             isSubmitted ?
                    <form onSubmit={(e) => submitAddition(e)} className={styles['form']}>
                        <h3>Add {type}</h3>
                        <input name="newData" type="text" value={newData} onChange={(e) => setNewData(e.target.value)}/>
                        <input type={'submit'} value={'Add'} />
                    </form>
            :
            (isSubmitting ? 
                <p>Submitting input</p> :
            <>
                <p>Input submitted. Click the button below to add more.</p>
                <button onClick={() => setIsSubmitted(true)} className={styles['add']}>Add</button>
            </>)
            }
            {type !== 'Reports' &&
                <div>
                    <h3>Name</h3>
                </div>}
            <div>
                {result.length !== 0 && 
                result.map((item, i) => {
                return(
                <Link to={'#'} key={i}>
                    <label>{item.name}</label>
                </Link>)})}
            </div>
        </div>
    </>
};

export default Admin;