import React,{ useState } from "react";
import styles from "./css/index.module.css";
import Header from "../../Components/Header";
import { BASE_URL, GET_OBJ, INSERT_DONOR } from "../../utils/endpoints";
import axios from "axios";
import { CLASSES, PLEDGECATEGORY, PLEDGETYPE, STATES } from "../../utils/optionData";
import { useEffect } from "react";

const Home = () => {
    const [userData, setUserData] = useState({ name: "", phonenumber: "", email: ""});
    const [pledges, setPledges] = useState([{
                                                pledgetype : "",
                                                pledgecategory : "",
                                                year : "",
                                                pledgeamount : "", 
                                                orphans : [{
                                                    name: "", 
                                                    school: "", 
                                                    state: "", 
                                                    currentClass: "", 
                                                    adoptionClass: "", 
                                                    cycle: "",
                                                    partner: ""
                                                }],
                                                widows : [{
                                                    name: "", 
                                                    state: "", 
                                                    programme: "", 
                                                    programmeDate: "", 
                                                    startupGiven: "", 
                                                    cycle: "",
                                                    partner: ""
                                                }],
                                                contributions : [{
                                                    datedonated: "", 
                                                    amount: ""
                                                }]
                                            }]);
    const [isUploaded, setIsUploaded] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [schools, setSchools] = useState([]);
    const [programmes, setProgrammes] = useState([]);
    const [cycles, setCycles] = useState([]);
    const [partners, setPartners] = useState([]);

    useEffect(() => {
        let getSchool = async () => {
            await axios.get(BASE_URL + GET_OBJ['School'])
            .then((response) => {
                if(Array.isArray(response.data))
                    setSchools(response.data);
            }).catch((e) =>{
            
            })
        }
        getSchool();

        let getProgramme = async () => {
            await axios.get(BASE_URL + GET_OBJ['Programme'])
            .then((response) => {
                if(Array.isArray(response.data))
                    setProgrammes(response.data);
            }).catch((e) =>{
            
            })
        }
        getProgramme();

        let getCycle = async () => {
            await axios.get(BASE_URL + GET_OBJ['Cycle'])
            .then((response) => {
                if(Array.isArray(response.data))
                    setCycles(response.data);
            }).catch((e) =>{
            
            })
        }
        getCycle();

        let getPartner = async () => {
            await axios.get(BASE_URL + GET_OBJ['Partner'])
            .then((response) => {
                if(Array.isArray(response.data))
                    setPartners(response.data);
            }).catch((e) =>{
            
            })
        }
        getPartner();
    },[]);

    const handleInputChange = (e) => {
        setUserData((prevState) => {
        return {
            ...prevState,
            [e.target.name]: e.target.value,
        };
        });
    };

    const handlePhoneChange = (e) => {
        const limit = 15;

        setUserData((prevState) => {
        return {
            ...prevState,
            [e.target.name]: e.target.value.slice(0, limit),
        };
        });
    };

    const handlePledgesChange = (parentIndex, event) => {
        let pledgesArray = [...pledges];
        pledgesArray[parentIndex][event.target.name] = event.target.value;
        setPledges(pledgesArray);
    };

    const handlePledgesDateChange = (parentIndex, event) => {
        let pledgesArray = [...pledges];
        const selected = event.target.value;
        const maxDate = new Date();
        if(selected > maxDate.getFullYear())
            pledgesArray[parentIndex][event.target.name] = "";
        else
            pledgesArray[parentIndex][event.target.name] = event.target.value;
        setPledges(pledgesArray);
    };

    const handleOrphanChange = (parentIndex, childIndex, event) => {
        let pledgesArray = [...pledges];
        pledgesArray[parentIndex]["orphans"][childIndex][event.target.name] = event.target.value;
        setPledges(pledgesArray);
    };

    const handleWidowChange = (parentIndex, childIndex, event) => {
        let pledgesArray = [...pledges];
        pledgesArray[parentIndex]["widows"][childIndex][event.target.name] = event.target.value;
        setPledges(pledgesArray);
    };

    const handleWidowDateChange = (parentIndex, childIndex, event) => {
        let pledgesArray = [...pledges];
        const selected = new Date(event.target.value);
        const maxDate = new Date();
        maxDate.setHours(0, 0, 0, 0);
        maxDate.setDate(maxDate.getDate() + 1);
        if (selected < maxDate) {
            pledgesArray[parentIndex]["widows"][childIndex][event.target.name] = event.target.value;
        } else {
            pledgesArray[parentIndex]["widows"][childIndex][event.target.name] = "";
        }
        
        
        setPledges(pledgesArray);
    }

    const handleContributionsChange = (parentIndex, childIndex, event) => {
        let pledgesArray = [...pledges];
        pledgesArray[parentIndex]["contributions"][childIndex][event.target.name] = event.target.value;
        setPledges(pledgesArray);
    };

    const addPledgesField = () => {
        let pledgesArray = JSON.parse(JSON.stringify(pledges));
        let newPledgeField = pledgesArray.pop();
        newPledgeField["year"] = "";
        newPledgeField["pledgeamount"] = "";
        newPledgeField["contributions"] = [{
            datedonated: "", 
            amount: ""
        }];
        setPledges([...pledges, newPledgeField]);
      }
    
    const removePledgesField = (i) => {
        let pledgesArray = [...pledges];
        pledgesArray.splice(i, 1);
        setPledges(pledgesArray);
    }

    const addOrphanField = (parentIndex) => {
        let pledgesArray = [...pledges];
        pledgesArray[parentIndex]["orphans"].push({
            name: "", 
            school: "", 
            state: "", 
            currentClass: "", 
            adoptionClass: "", 
            cycle: "",
            partner: ""
        });
        setPledges(pledgesArray);
      }
    
    const removeOrphanField = (parentIndex,childIndex) => {
        let pledgesArray = [...pledges];
        pledgesArray[parentIndex]["orphans"].splice(childIndex, 1);
        setPledges(pledgesArray);
    }

    const addWidowField = (parentIndex) => {
        let pledgesArray = [...pledges];
        pledgesArray[parentIndex]["widows"].push({
            name: "", 
            state: "", 
            programme: "", 
            programmeDate: "", 
            startupGiven: "", 
            cycle: "",
            partner: ""
        });
        setPledges(pledgesArray);
      }
    
    const removeWidowField = (parentIndex,childIndex) => {
        let pledgesArray = [...pledges];
        pledgesArray[parentIndex]["widows"].splice(childIndex, 1);
        setPledges(pledgesArray);
    }

    const addContributionsField = (parentIndex) => {
        let pledgesArray = [...pledges];
        pledgesArray[parentIndex]["contributions"].push({
            datedonated: "", 
            amount: ""
        });
        setPledges(pledgesArray);
      }
    
    const removeContributionsField = (parentIndex,childIndex) => {
        let pledgesArray = [...pledges];
        pledgesArray[parentIndex]["contributions"].splice(childIndex, 1);
        setPledges(pledgesArray);
    }

    const submitDonor = async (e) => {
        e.preventDefault();
        if(userData.name === "" || 
           userData.email === "" || 
           userData.phonenumber === "" ){
            setErrorMessage("Fill in all the fields");
            setIsUploaded("");
            return null;
        }

        for(var i = 0; i < pledges.length; i++){
            if(pledges[i].pledgeamount === "" ||
            pledges[i].pledgetype === "" ||
            pledges[i].year === "" ||
            pledges[i].pledgecategory === ""){
                setErrorMessage("Fill in all the fields");
                setIsUploaded("");
                return null;
            }

            for(var p = 0; p < pledges[i].contributions.length; p++){
                if(pledges[i].contributions[p].amount === "" ||
                pledges[i].contributions[p].datedonated === ""){
                    setErrorMessage("Fill in all the fields");
                    setIsUploaded("");
                    return null;
                }
            }
    
            if(pledges[i].pledgecategory === "orphan"){
                for(var m = 0; m < pledges[i].orphans.length; m++){
                    if(pledges[i].orphans[m].name === "" ||
                    pledges[i].orphans[m].school === "" ||
                    pledges[i].orphans[m].state === "" ||
                    pledges[i].orphans[m].adoptionClass === "" ||
                    pledges[i].orphans[m].currentClass === "" ||
                    pledges[i].orphans[m].cycle === "" ||
                    pledges[i].orphans[m].partner === ""){
                        setErrorMessage("Fill in all the fields");
                        setIsUploaded("");
                        return null;
                    }
                }
            }
    
            if(pledges[i].pledgecategory === "widow"){
                for(var j = 0; j < pledges[i].widows.length; j++){
                    if(pledges[i].widows[j].name === "" ||
                    pledges[i].widows[j].state === "" ||
                    pledges[i].widows[j].programme === "" ||
                    pledges[i].widows[j].programmeDate === "" ||
                    pledges[i].widows[j].startupGiven === "" ||
                    pledges[i].widows[j].cycle === "" || 
                    pledges[i].widows[j].partner === ""){
                        setErrorMessage("Fill in all the fields");
                        setIsUploaded("");
                        return null;
                    }
                }
            }
    
            if(pledges[i].pledgecategory === "both"){
                for(var k = 0; k < pledges[i].orphans.length; k++){
                    if(pledges[i].orphans[k].name === "" ||
                    pledges[i].orphans[k].school === "" ||
                    pledges[i].orphans[k].state === "" ||
                    pledges[i].orphans[k].adoptionClass === "" ||
                    pledges[i].orphans[k].currentClass === "" ||
                    pledges[i].orphans[k].cycle === "" ||
                    pledges[i].orphans[k].partner === ""){
                        setErrorMessage("Fill in all the fields");
                        setIsUploaded("");
                        return null;
                    }
                }
                for(var l = 0; l < pledges[i].widows.length; l++){
                    if(pledges[i].widows[l].name === "" ||
                    pledges[i].widows[l].state === "" ||
                    pledges[i].widows[l].programme === "" ||
                    pledges[i].widows[l].programmeDate === "" ||
                    pledges[i].widows[l].startupGiven === "" ||
                    pledges[i].widows[l].cycle === "" ||
                    pledges[i].widows[l].partner === ""){
                        setErrorMessage("Fill in all the fields");
                        setIsUploaded("");
                        return null;
                    }
                }
            }
        }


        setIsSubmitting(true);

        for(var n = 0; n < pledges.length; n++){
            if(pledges[n].pledgecategory === "none" || 
            pledges[n].pledgecategory === "widow")
                delete pledges[n].orphans;

            if(pledges[n].pledgecategory === "none" || 
            pledges[n].pledgecategory === "orphan")
                delete pledges[n].widows;
        }

        let obj = {...userData};

        obj.pledges = pledges;
        //console.log(obj);
        //return null;
        
        const jsonObj = JSON.stringify(obj);
        await axios.post(BASE_URL + INSERT_DONOR, jsonObj)
          .then((response) => {
            setUserData((prevState) => {
                return { 
                    ...prevState,
                    name: "",
                    phonenumber: "",
                    email: "",
                }
            });
            setPledges([{
                pledgetype : "",
                pledgecategory : "",
                year : "",
                pledgeamount : "", 
                orphans : [{
                    name: "", 
                    school: "", 
                    state: "", 
                    currentClass: "", 
                    adoptionClass: "", 
                    cycle: "",
                    partner: ""
                }],
                widows : [{
                    name: "", 
                    state: "", 
                    programme: "", 
                    programmeDate: "", 
                    startupGiven: "", 
                    cycle: "",
                    partner: ""
                }],
                contributions : [{
                    datedonated: "", 
                    amount: ""
                }]
            }]);
            setIsUploaded("Donor has uploaded successfully!");
            setErrorMessage("");
            setIsSubmitting(false);
          }).catch((e) =>{
            
          })
    }

    const clearForm = () => {
        setUserData((prevState) => {
            return { 
                ...prevState,
                name: "",
                phonenumber: "",
                email: "",
            }
        });
        setPledges([{
            pledgetype : "",
            pledgecategory : "",
            year : "",
            pledgeamount : "", 
            orphans : [{
                name: "", 
                school: "", 
                state: "", 
                currentClass: "", 
                adoptionClass: "", 
                cycle: "",
                partner: ""
            }],
            widows : [{
                name: "", 
                state: "", 
                programme: "", 
                programmeDate: "", 
                startupGiven: "", 
                cycle: "",
                partner: ""
            }],
            contributions : [{
                datedonated: "", 
                amount: ""
            }]
        }]);
        setErrorMessage("");
        setIsUploaded("");
    }


    return(
    <>
    <Header/>
    { isSubmitting ? 
    <div className={styles["loading-screen"]}>
        <p>Please wait while we upload your entry...</p>
    </div> :
    <div className={styles["form-container"]}>
        <div className={styles["padding"]}>
            <div>
                <label>Name</label>
                <input type="text" name="name" onChange={handleInputChange} value={userData.name}/>
            </div>
            <div>
                <label>Phone Number</label>
                <input type="number" name="phonenumber" onChange={handlePhoneChange} value={userData.phonenumber}/>
            </div>
            <div>
                <label>Email</label>
                <input type="text" name="email" onChange={handleInputChange} value={userData.email}/>
            </div>
        </div>
        <div className={styles["pledges"]}>
            <h2>Pledges</h2>
            {pledges.map((pledge, parentIndex) => (
            <div key={parentIndex} className={styles["pledge"]}>
                <h3>{parentIndex + 1}.</h3>
                <div>
                    <label>Pledge Type</label>
                    <select name="pledgetype" onChange={(e) => handlePledgesChange(parentIndex, e)} value={pledge.pledgetype}>
                        <option value="">Select Pledge Type</option>
                        {PLEDGETYPE.map((pledgetype, i) => (
                            <option value={pledgetype} key={i}>{pledgetype}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Pledge Category</label>
                    <select name="pledgecategory" onChange={(e) => handlePledgesChange(parentIndex, e)} value={pledge.pledgecategory}>
                        <option value="">Select Category</option>
                        {PLEDGECATEGORY.map((pledgecategory, i) => (
                            <option value={pledgecategory} key={i}>{pledgecategory}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Year</label>
                    <input name="year" type={"number"} onChange={(e) => handlePledgesDateChange(parentIndex, e)} value={pledge.year} />
                </div>
                <div>
                    <label>Pledge Amount</label>
                    <input name="pledgeamount" type={"number"} onChange={(e) => handlePledgesChange(parentIndex, e)} value={pledge.pledgeamount} />
                </div>
                { (pledge.pledgecategory === "orphan" || pledge.pledgecategory === "both") && 
                <div className={styles["orphan-section"]}>
                    <h4>Orphans</h4>
                    {pledge.orphans.map((orphan, childIndex) => (
                    <div key={childIndex}>
                        <h5>{childIndex + 1}.</h5>
                        <div>
                            <label>Name</label>
                            <input name="name" onChange={(e) => handleOrphanChange(parentIndex, childIndex, e)} value={orphan.name}/>
                        </div>
                        <div>
                            <label>School</label>
                            <select name="school" onChange={(e) => handleOrphanChange(parentIndex, childIndex, e)} value={orphan.school}>
                                <option value={""}>Select School</option>
                                {schools.map((school, i) => (
                                    <option value={school.name} key={i}>{school.name}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>State</label>
                            <select name="state" onChange={(e) => handleOrphanChange(parentIndex, childIndex, e)} value={orphan.state}>
                                <option value={""}>Select State</option>
                                {STATES.map((state, i) => (
                                    <option value={state} key={i}>{state}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>Current Class</label>
                            <select name="currentClass" onChange={(e) => handleOrphanChange(parentIndex, childIndex, e)} value={orphan.currentClass}>
                                <option value={""}>Select Current Class</option>
                                {CLASSES.map((classlevel, i) => (
                                    <option value={classlevel} key={i}>{classlevel}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>Adoption Class</label>
                            <select name="adoptionClass" onChange={(e) => handleOrphanChange(parentIndex, childIndex, e)} value={orphan.adoptionClass}>
                                <option value={""}>Select Adoption Class</option>
                                {CLASSES.map((classlevel, i) => (
                                    <option value={classlevel} key={i}>{classlevel}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>Cycle</label>
                            <select name="cycle" onChange={(e) => handleOrphanChange(parentIndex, childIndex, e)} value={orphan.cycle}>
                                <option value={""}>Select Cycle</option>
                                {cycles.map((cycle, i) => (
                                    <option value={cycle.name} key={i}>{cycle.name}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>Partner</label>
                            <select name="partner" onChange={(e) => handleOrphanChange(parentIndex, childIndex, e)} value={orphan.partner}>
                                <option value={""}>Select Partner</option>
                                {partners.map((partner, i) => (
                                    <option value={partner.name} key={i}>{partner.name}</option>
                                ))}
                            </select>
                        </div>
                        {childIndex ?
                        <button onClick={() => removeOrphanField(parentIndex, childIndex)}>Remove Orphan</button> : 
                        null}
                    </div>
                    ))}
                    <button className={styles["add-orphan"]} onClick={() => addOrphanField(parentIndex)}>Add Orphan</button>
                </div>
                }
                { (pledge.pledgecategory === "widow" || pledge.pledgecategory === "both") && 
                <div className={styles["widow-section"]}>
                    <h4>Widows</h4>
                    {pledge.widows.map((widow, childIndex) => (
                    <div key={childIndex}>
                        <h5>{childIndex + 1}.</h5>
                        <div>
                            <label>Name</label>
                            <input name="name" type="text" onChange={(e) => handleWidowChange(parentIndex, childIndex, e)} value={widow.name}/>
                        </div>
                        <div>
                            <label>State</label>
                            <select name="state" type="text" onChange={(e) => handleWidowChange(parentIndex, childIndex, e)} value={widow.state}>
                                <option value={""}>Select State</option>
                                {STATES.map((state, i) => (
                                    <option value={state} key={i}>{state}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>Programme</label>
                            <select name="programme"  onChange={(e) => handleWidowChange(parentIndex, childIndex, e)} value={widow.programme}>
                                <option value={""}>Select Programme</option>
                                {programmes.map((programme, i) => (
                                    <option value={programme.name} key={i}>{programme.name}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>Programme Date</label>
                            <input name="programmeDate" type="date" onChange={(e) => handleWidowDateChange(parentIndex, childIndex, e)} value={widow.programmeDate}/>
                        </div>
                        <div>
                            <label>Start-Up Given</label>
                            <input name="startupGiven" type="number" onChange={(e) => handleWidowChange(parentIndex, childIndex, e)} value={widow.startupGiven}/>
                        </div>
                        <div>
                            <label>Cycle</label>
                            <select name="cycle" onChange={(e) => handleWidowChange(parentIndex, childIndex, e)} value={widow.cycle}>
                                <option value={""}>Select Cycle</option>
                                {cycles.map((cycle, i) => (
                                    <option value={cycle.name} key={i}>{cycle.name}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>Partner</label>
                            <select name="partner" onChange={(e) => handleOrphanChange(parentIndex, childIndex, e)} value={widow.partner}>
                                <option value={""}>Select Partner</option>
                                {partners.map((partner, i) => (
                                    <option value={partner.name} key={i}>{partner.name}</option>
                                ))}
                            </select>
                        </div>
                        {childIndex ?
                        <button onClick={() => removeWidowField(parentIndex, childIndex)}>Remove Widow</button> : 
                        null}
                    </div>
                    ))}
                    <button className={styles["add-widow"]} onClick={() => addWidowField(parentIndex)}>Add Widow</button>
                </div>
                }
                <div className={styles["donor-contributions"]}>
                    <h4>Donor Contributions</h4>
                    {pledge.contributions.map((contribution, childIndex) => (
                    <div key={childIndex}>
                        <h5>{childIndex + 1}.</h5>
                        <div>
                            <label>Date Donated</label>
                            <input type={"date"} name="datedonated" onChange={(e) => handleContributionsChange(parentIndex, childIndex, e)} value={contribution.datedonated}/>
                        </div>
                        <div>
                            <label>Amount</label>
                            <input type={"number"} name="amount" onChange={(e) => handleContributionsChange(parentIndex, childIndex, e)} value={contribution.amount} />
                        </div>
                        {childIndex ?
                        <button className={styles["add-contribution"]} onClick={() => removeContributionsField(parentIndex, childIndex)}>Remove Contribution</button> : 
                        null}
                    </div>
                    ))}
                    <button className={styles["add-contribution"]} onClick={() => addContributionsField(parentIndex)}>Add Contribution</button>
                </div>
                { (pledge.pledgecategory === "orphan" || pledge.pledgecategory === "both") &&
                <p><label>Total Orphans</label> : {pledge.orphans.length}</p>
                }
                { (pledge.pledgecategory === "widow" || pledge.pledgecategory === "both") &&
                <p><label>Total Widows</label> : {pledge.widows.length}</p>
                }
                {parentIndex ?
                <button className={styles["remove-pledge"]} onClick={() => removePledgesField(parentIndex)}>Remove Pledge</button> : 
                null}
            </div>
            ))}
            <div className={styles["add-pledge"]}>
                <button onClick={addPledgesField}>Add Pledge</button>
            </div>
        </div>
        <span>{errorMessage}</span>
        <span>{isUploaded}</span>
        <div className={styles["padding"]}>
            <div>
                <button onClick={submitDonor}>
                    Submit
                </button>
                <button onClick={clearForm}>
                    Clear
                </button>
            </div>
        </div>
    </div>}
    </>
    )
}
export default Home;