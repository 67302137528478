import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../Components/Header";
import axios from "axios";
import { BASE_URL, GET_BENEFICIARY } from "../../utils/endpoints";
import styles from "./css/index.module.css";


const Beneficiary = () =>{

    let { beneficiary, id } = useParams();
    const [stateBeneficiary,setBeneficiary] = useState(null);

    useEffect(() => {
        let getBeneficiaries = async () => {
            await axios.get(`${BASE_URL}${GET_BENEFICIARY}?type=${beneficiary}&id=${id}`)
            .then((response) => {
                if(typeof response.data === 'object')
                    setBeneficiary(response.data);
            }).catch((e) =>{
            
            })
        }
        getBeneficiaries();
    },[beneficiary,id])

    return<>
        <Header/>
        <h1 className={styles["header"]}>
        {beneficiary === 'orphan' && <>Orphan</>}
        {beneficiary === 'widow' && <>Widow</>}
        </h1>
        {stateBeneficiary !== null &&
        <div className={styles["container"]}>
            <div className={styles["name"]}>
                <label>Name</label>
                <span>{stateBeneficiary.name}</span>
            </div>
            {beneficiary === 'orphan' && 
            <div>
                {stateBeneficiary.pledges.map((pledge, index) => (
                    <div key={index} className={styles["section"]}>
                        <h2>Pledge {index + 1}.</h2>
                        <div>
                            <div>
                                <label>Donor Name</label>
                                <span>{pledge.name}</span>
                            </div>
                            <div>
                                <label>Donor Email</label>
                                <span>{pledge.email}</span>
                            </div>
                            <div>
                                <label>Donor Phone Number</label>
                                <span>{pledge.phonenumber}</span>
                            </div>
                            <div>
                                <label>Pledge Year</label>
                                <span>{pledge.year}</span>
                            </div>
                            <div>
                                <label>Pledge Amount</label>
                                <span>{pledge.pledgeamount}</span>
                            </div>
                        </div>
                        <div>
                            <div>
                                <label>School</label>
                                <span>{pledge.school}</span>
                            </div>
                            <div>
                                <label>State</label>
                                <span>{pledge.state}</span>
                            </div>
                            <div>
                                <label>Current Class</label>
                                <span>{pledge.currentClass}</span>
                            </div>
                            <div>
                                <label>Adoption Class</label>
                                <span>{pledge.adoptionClass}</span>
                            </div>
                            <div>
                                <label>Cycle</label>
                                <span>{pledge.cycle}</span>
                            </div>
                            <div>
                                <label>Partner</label>
                                <span>{pledge.partner}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>}
            {beneficiary === 'widow' && 
            <div>
                {stateBeneficiary.pledges.map((pledge, index) => (
                    <div key={index} className={styles["section"]}>
                        <h2>Pledge {index + 1}.</h2>
                        <div>
                            <div>
                                <label>Donor Name</label>
                                <span>{pledge.name}</span>
                            </div>
                            <div>
                                <label>Donor Email</label>
                                <span>{pledge.email}</span>
                            </div>
                            <div>
                                <label>Donor Phone Number</label>
                                <span>{pledge.phonenumber}</span>
                            </div>
                            <div>
                                <label>Pledge Year</label>
                                <span>{pledge.year}</span>
                            </div>
                            <div>
                                <label>Pledge Amount</label>
                                <span>{pledge.pledgeamount}</span>
                            </div>
                        </div>
                        <div>
                            <div>
                                <label>State</label>
                                <span>{pledge.state}</span>
                            </div>
                            <div>
                                <label>Programme</label>
                                <span>{pledge.programme}</span>
                            </div>
                            <div>
                                <label>Programme Date</label>
                                <span>{pledge.programmeDate}</span>
                            </div>
                            <div>
                                <label>Startup Given</label>
                                <span>{pledge.startupGiven}</span>
                            </div>
                            <div>
                                <label>Cycle</label>
                                <span>{pledge.cycle}</span>
                            </div>
                            <div>
                                <label>Partner</label>
                                <span>{pledge.partner}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>}
        </div>}
    </>
}

export default Beneficiary;